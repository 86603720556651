export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('amount', {
    beforeMount (el) {
      const handler = (e: Event) => {
        if (e.target) {
          const regex = /^-?(0|[1-9]\d*)?([.,]\d*)?$/
          const eventTarget = (e.target as HTMLInputElement)

          // if the new value is not a match for the regex,
          // reverse the input event by setting the value back to its old value
          if (!regex.test(eventTarget.value)) {
            (eventTarget as HTMLInputElement).value = eventTarget.defaultValue
          } else {
            // If the input event is valid, update defaultValue to the newest valid value
            eventTarget.defaultValue = eventTarget.value
          }
        }
      }

      el.handler = handler // save a reference to the handler
      el.addEventListener('input', handler)
    },
    unmounted (el) {
      el.removeEventListener('input', el.handler) // unbind the saved handler
    }
  })

  nuxtApp.vueApp.directive('auto-width', {
    beforeMount (el, binding) {
      const inputEl = el.querySelector('input')

      // Create a separete element for width calculation
      const widthMachine = document.createElement('span')
      widthMachine.style.position = 'absolute'
      widthMachine.style.visibility = 'hidden'
      widthMachine.style.whiteSpace = 'nowrap'
      widthMachine.style.whiteSpace = 'nowrap'
      if (!binding.value) {
        widthMachine.className = 'text-2xl'
      } else {
        widthMachine.className = binding.value
      }

      el.appendChild(widthMachine)

      const resize = () => {
        // Use non-breaking spaces to measure width
        widthMachine.textContent = inputEl.value.replace(/ /g, '\xA0')
        const parentWidth = el.parentElement.clientWidth // Grab the width of the parent
        const maxWidth = Math.max(100, parentWidth)
        const newWidth = Math.min(maxWidth, widthMachine.offsetWidth)
        inputEl.style.width = `${Math.max(newWidth, 100) + 10}px`
      }

      inputEl.handler = resize // save a reference to the handler
      inputEl.addEventListener('input', resize)
      window.addEventListener('resize', resize)
    },
    mounted (el) {
      setTimeout(() => {
        const inputEl = el.querySelector('input')
        inputEl.handler() // Resize when input is mounted
      }, 50)

      // second timeout to fix the issue with the input not being resized on login
      setTimeout(() => {
        const inputEl = el.querySelector('input')
        inputEl.handler() // Resize when input is mounted
      }, 500)
    },
    unmounted (el) {
      const inputEl = el.querySelector('input')
      el.removeChild(el.querySelector('span')) // Remove the widthMachine element while unmount
      inputEl.removeEventListener('input', inputEl.handler) // clean up, unbind the saved handler
      window.removeEventListener('resize', inputEl.handler)
    }
  })
})
