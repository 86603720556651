import {
  differenceInDays,
  endOfMonth,
  isToday,
  isTomorrow,
  isThisMonth,
  endOfYear,
  format,
  setDate,
  startOfMonth,
  startOfYear,
  setDay,
  addMonths,
  differenceInMonths,
  differenceInCalendarMonths,
  isThisYear
} from 'date-fns'
import {transformAmount} from "~/composables/AmountModifier";

export const getGoalDates = (goal: Goal) => {
  const today = new Date()
  let dueDate: Date = new Date()
  let daysInPeriod: number = 0

  switch (goal.frequency) {
    case 'monthly':
      if (goal.repeat_every === 1) {
        dueDate = goal.due_by === 'last' ? endOfMonth(today) : new Date(today.getFullYear(), today.getMonth(), goal.due_by as number)
      } else {
        const monthsSinceAnchor = differenceInMonths(today, new Date(goal.repeat_anchor || new Date()))
        const periodsSinceAnchor = Math.floor(monthsSinceAnchor / goal.repeat_every)
        const duePeriod = addMonths(new Date(goal.repeat_anchor || new Date()), (periodsSinceAnchor + 1) * goal.repeat_every)
        if(goal.due_by === 'last') {
          dueDate = endOfMonth(duePeriod)
        } else if(typeof goal.due_by === "number") {
          // if due_by is a number, it's a certain day of the month
          dueDate = setDate(duePeriod, goal.due_by)
        } else {
          dueDate = duePeriod
        }
      }
      daysInPeriod = differenceInDays(endOfMonth(today), startOfMonth(today)) + 1
      break
    case 'weekly':
      if(typeof goal.due_by !== 'number' || ![1,2,3,4,5,6,7].includes(goal.due_by)) {
        goal.due_by = 1
      }

      dueDate = setDay(dueDate, goal.due_by)
      daysInPeriod = 7
      break
    case 'yearly':
    case 'once':
      dueDate = new Date(goal.due_by as Date)
      daysInPeriod = differenceInDays(endOfYear(today), startOfYear(today)) + 1
      break
  }

  return {
    dueDate,
    daysUntilDue: differenceInDays(dueDate, today),
    monthsUntilDue: differenceInCalendarMonths(dueDate, today),
    daysInPeriod
  }
}

function calculateUnderfunded(category: Category, dueDate: Date, today: Date) {
  const monthsUntilDue = differenceInCalendarMonths(dueDate, today) + 1

    let underfunded;
    if(monthsUntilDue <= 1) {
      underfunded = transformAmount(category.goal.target - category.goal.overall_funded)
    } else {
      underfunded = transformAmount((category.goal.target / (monthsUntilDue)) - category.goal.overall_funded)
    }

  if(category.goal.type === 'saving') {
    underfunded += category.spent
  }


  return {
    underfunded,
    monthsUntilDue
  }
}

export const getCategoryUnderfunded = (category: Category, today: Date = new Date()) => {
  if(category.goal.type === 'nt') {
    return -category.balance
  }

  const { goal } = category
  const { dueDate } = getGoalDates(goal)
  let underfunded = 0

  switch (goal.frequency) {
    case 'monthly':
      if (goal.repeat_every === 1) {
        const spent = category.goal.type === 'saving' ? category.spent : 0
        underfunded = transformAmount(goal.target - category.budgeted + spent)
      } else {
        underfunded = calculateUnderfunded(category, dueDate, today).underfunded
      }
      break
    case 'weekly':
      const spent = category.goal.type === 'saving' ? category.spent : 0
      underfunded = transformAmount(goal.target - category.budgeted + spent)
      break
    case 'yearly':
    case 'once':
      underfunded = calculateUnderfunded(category, dueDate, today).underfunded
      break
    default:
      throw new Error(`Invalid frequency: ${goal.frequency}`)
  }

  return underfunded
}

export const getCategoryOverspentPercentage = (category: Category) => {
  let underfunded = category.underfunded

  if(category.goal.type === 'nt') {
    if(category.spent > category.budgeted) return 1
    return Math.min((category.spent) / (category.budgeted), 1)
  }

  if(category.goal.type === 'spending') {
    return Math.min((category.spent) / (underfunded + category.budgeted), 1)
  }

  //saving
  return Math.max((category.spent) / (category.budgeted + underfunded), 0)
}

export const getCategoryProgress = (category: Category) => {
    if (category.goal.type === 'nt') {
      if(category.spent === 0 && category.budgeted <= 0) {
        return 0
      }
      return Math.min(category.budgeted / category.spent, 1)*100
    }

    return category.goal.progress*100
}

export const getCategoryProgressText = (category: Category, currency: Currency, simple: boolean = true) => {
  const underfunded = category.underfunded
  const { dueDate } = getGoalDates(category.goal)

  if (category.goal.type === 'nt') {
    if (underfunded > 0) {
      return `${formatAmountWithCurrency(underfunded, currency)} more needed`
    } else {
      if(category.balance === 0 && category.spent === 0) {
        return 'Empty'
      }
      return 'Funded'
    }
  }

  let append = ''

  if(!simple) {
    if(category.goal.frequency === 'weekly') {
        append = 'week'
    } else {
        append = 'month'
    }
  }

  if (underfunded === 0) {
    return simple ? 'Funded' : `You\'ve reached your target for this ${append}!`
  } else if (underfunded < 0) {
    return simple ? 'Overfunded by ' + formatAmountWithCurrency(-underfunded, currency) : `You\'ve reached your target for this ${append}!`
  }

  if(isToday(dueDate)) {
    return `${formatAmountWithCurrency(underfunded, currency)} more needed today`
  } else if(isTomorrow(dueDate)) {
    return `${formatAmountWithCurrency(underfunded, currency)} more needed by tomorrow`
  } else if(category.goal.frequency === 'monthly') {
    if(typeof category.goal.due_by === 'number' && isThisMonth(dueDate)) {
      // if due_by is a number, it's a certain day of the month, meaning we should return the specific date
      return `${formatAmountWithCurrency(underfunded, currency)} more needed by ${format(dueDate, 'MMM d')}`
    } else {
      // if due_by is 'last', it's the last day of the month, meaning we should return 'this month'
      return `${formatAmountWithCurrency(underfunded, currency)} more needed this month`
    }
  } else {
    // if frequency is once or yearly, back to monthly logic unless due date is this month
    if(isThisMonth(dueDate) || category.goal.frequency === 'weekly') {
      return `${formatAmountWithCurrency(underfunded, currency)} more needed by ${format(dueDate, 'MMM d')}`
    } else {
      return `${formatAmountWithCurrency(underfunded, currency)} more needed this month`
    }
  }
}

export const getCategoryTargetText = (category: Category, currency: Currency) => {
    if (category.goal.type === 'nt') {
        return ''
    }

    const { goal } = category
    const { dueDate } = getGoalDates(goal)
    let append = ''

    if(goal.frequency !== 'once') {
      switch (goal.frequency) {
        case 'monthly':
            if (goal.repeat_every === 1) {
                append = `, repeating every month`
            } else {
                append = `, repeating every ${goal.repeat_every} months`
            }
            break
        case 'weekly':
            if (goal.repeat_every === 1) {
              append = `, repeating every week`
            } else {
              append = `, repeating every ${goal.repeat_every} weeks`
            }
            break
        case 'yearly':
            if (goal.repeat_every === 1) {
              append = `, repeating every year`
            } else {
              append = `, repeating every ${goal.repeat_every} years`
            }
            break
      }
    }

    if(isThisYear(dueDate)) {
      return `${formatAmountWithCurrency(goal.target, currency)} by ${format(dueDate, 'MMMM d')}${append}`
    } else {
      return `${formatAmountWithCurrency(goal.target, currency)} by ${format(dueDate, 'MMMM d, yyyy')}${append}`
    }
}

export function updateAreaProps(area: Area) {
  let totalBudgeted = 0; let totalRequired = 0; let totalSpent = 0; let totalBalance = 0

  if (area.categories.length === 0) {
    area.progress = 0
    area.budgeted = 0
    area.spent = 0
    area.balance = 0
    return
  }

  area.categories.forEach((category) => {
    category.area_id = area.id
    const underfunded = getCategoryUnderfunded(category)
    totalBudgeted += category.budgeted
    totalRequired += (underfunded + category.budgeted)
    totalSpent += category.spent
    totalBalance += category.balance

    category.underfunded = underfunded

    if (category.goal.type !== 'nt') {
      category.goal.progress = Math.min(category.budgeted / (category.budgeted + underfunded), 1)

      if(category.goal.type === 'spending') {
        category.goal.overall_progress = Math.max(Math.min(category.goal.overall_funded / category.goal.target, 1), 0)
      } else {
        category.goal.overall_progress = Math.max(Math.min((category.goal.overall_funded - category.spent) / category.goal.target, 1), 0)
      }
    }
  })

  if (totalRequired === 0) {
    if(totalBudgeted < 0) {
      area.progress = 0
    } else {
      area.progress = 1
    }
  } else {
    area.progress = Math.max(Math.min(totalBudgeted / totalRequired, 1), 0)
  }

  area.budgeted = totalBudgeted
  area.spent = totalSpent
  area.balance = totalBalance
}

export function handleAssign (category: Category, assigned: { type: 'add'|'set', value: number, difference: number }, apiSync: boolean = true) {
  const { $budgetStore, $areaStore } = useNuxtApp()
  const { type, value, difference } = assigned

  const oldBudgeted = category.budgeted

  if(type === 'add') {
    category.budgeted += value
    category.goal.overall_funded += value
    category.balance += value

    $budgetStore.$patch((state) => {
      state.budget.balance -= value
      state.budget.budgeted += value
    })
  } else {
    category.budgeted = value
    category.goal.overall_funded += difference
    category.balance += difference

    $budgetStore.$patch((state) => {
      state.budget.balance -= difference
      state.budget.budgeted += difference
    })
  }

  $areaStore.editCategoryBudgeted(category.id, category.budgeted, oldBudgeted, apiSync)
}

export function setAssigned(category: Category, value: number, callback?: () => void) {
  const transformedValue = transformAmount(value)
  const difference = transformAmount(transformedValue - category.budgeted)
  handleAssign(category, {type: 'set', value: transformedValue, difference})

  if(callback) {
    callback()
  }
}

export function generateLayoutKey() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}