import { defineStore } from 'pinia'
import { fetchActiveBudget } from "~/composables/useEnvolopApi";
import {useRuntimeConfig} from "#app";

export const useBudgetStore = defineStore('budget', {
  state: (): BudgetStore => ({
    budget: {} as Budget,
    layout: 'card-columns',
    area_skeletons: [
      { categories: 3 },
      { categories: 2 },
      { categories: 1 },
    ],
    last_fetch: 0,
  }),
  actions: {
    editBudgetLayout (layout: 'card-columns'|'card-rows'|'list') {
      this.layout = layout
    },
  },
  getters: {
    getActiveBudget: (state) => {
      return async (): Promise<Budget | undefined> => {
        const cacheExpiryMinutes = useRuntimeConfig().public.cacheExpiryMinutes as unknown as number * 60 * 1000
        const now = Date.now();

        if (now - state.last_fetch > cacheExpiryMinutes || !state.budget.id) {

          state.budget = await fetchActiveBudget()
          state.last_fetch = now;
        }

        return state.budget
      }
    },
  },
  persist: {
    storage: localStorage
  },
})