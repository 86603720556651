import { useUserStore } from '~/stores/user'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default defineNuxtRouteMiddleware((to, from) => {
  const userStore = useUserStore()

  // return user to login page if unauthenticated
  if (
      (to.path !== '/login' &&
      to.path !== '/signup' &&
      to.path !== '/forgot-password' &&
      to.path !== '/reset-password' &&
          to.path !== '/auth/callback/google')
      && !userStore.id) {
    return navigateTo('/login')
  }

  // return user to home if already authenticated
  if (
      (to.path === '/login' ||
      to.path === '/signup' ||
      to.path === '/forgot-password' ||
      to.path === '/reset-password' ||
          to.path === '/auth/callback/google')
      && userStore.id) {
    return navigateTo('/')
  }
})
