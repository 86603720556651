<script setup lang="ts">
useHead({
  meta: [
    { name: 'description', content: 'Budget like a pro' },
    { name: 'msapplication-TileColor', content: '#09090b' },
    { name: 'theme-color', content: '#09090b' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png?v=2' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png?v=2' },
    { rel: 'shortcut icon', type: 'image/png', sizes: '16x16', href: '/favicon.ico?v=2' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png?v=2' },
    { rel: 'manifest', href: '/site.webmanifest?v=2' }
  ],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Envolop` : 'Envolop'
  }
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
  </div>
</template>
