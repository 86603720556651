import {formatISO, isDate} from "date-fns";
import {useRuntimeConfig} from "#app";

export const fetchActiveBudget = async () => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets?active=true`)
    return response.data[0]
};

export const fetchBudgetWithAreas = async (budgetId: number) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}?include_areas=true`)
    const data = response.data

    // Transform the data to make it compatible
    data.areas.forEach((area: Area) => {
        area.progress = 0
        area.hidden = false
        area.overview_expanded = false
        area.budgeted = 0
        area.balance = 0
        area.spent = 0

        area.categories.forEach((category: Category) => {
            category.goal.progress = 0
            category.goal.overall_progress = 0
        })
    })

    return data
};

export const patchBudget = async (budgetId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};



// Areas

export const fetchAreas = async (budgetId: number) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas`)
    const areas = response.data

    // Transform the data to make it compatible
    areas.forEach((area: Area) => {
        area.progress = 0
        area.hidden = false
        area.overview_expanded = false
        area.budgeted = 0
        area.balance = 0
        area.spent = 0

        area.categories.forEach((category: Category) => {
            category.goal.progress = 0
            category.goal.overall_progress = 0
        })
    })

    return areas
};

export const postArea = async (budgetId: number, newArea: Area) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newArea),
    })

    return response.data
};

export const patchArea = async (budgetId: number, areaId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const assignArea = async (budgetId: number, areaId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/assign`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const putArea = async (budgetId: number, areaId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const delArea = async (budgetId: number, areaId: number) => {
    return await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
};



// Categories

export const postCategory = async (budgetId: number, areaId: number, newCategory: Category) => {
    // create new body tailored to the API
    const body = {
        name: newCategory.name,
        goal_type: newCategory.goal.type,
        goal_target: newCategory.goal.target,
        goal_frequency: newCategory.goal.frequency,
        goal_due_by: newCategory.goal.due_by,
        goal_repeat_every: newCategory.goal.repeat_every,
        goal_repeat_anchor: newCategory.goal.repeat_anchor ?
            formatISO(newCategory.goal.repeat_anchor as Date, { representation: 'date' }) :
            formatISO(new Date(), { representation: 'date' }),
    }

    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/categories`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const patchCategory = async (budgetId: number, areaId: number, categoryId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/categories/${categoryId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const reassignCategory = async (budgetId: number, areaId: number, categoryId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/categories/${categoryId}/reassign`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const putCategory = async (budgetId: number, areaId: number, categoryId: number, body: Object) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/categories/${categoryId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    return response.data
};

export const delCategory = async (budgetId: number, areaId: number, categoryId: number) => {
    const response = await $efetch(`${useRuntimeConfig().public.apiUrl}/api/v1/budgets/${budgetId}/areas/${areaId}/categories/${categoryId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return response
};