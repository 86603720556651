import { defineStore } from 'pinia'
import {getCategoryUnderfunded, updateAreaProps} from "~/composables/BudgetEngine";
import {
  patchArea,
  patchCategory,
  postArea,
  postCategory,
  putArea,
  delArea,
  delCategory, assignArea, fetchAreas, reassignCategory
} from "~/composables/useEnvolopApi";
import {useRuntimeConfig} from "#app";

let categoryMoveOld = {
  id: 0,
  areaId: 0,
  index: 0
}

export const useAreaStore = defineStore('areas', {
  state: (): AreaStore => ({
    budget_id: 0,
    areas: {} as Area[],
    layout_key: 0,
    last_fetch: 0,
  }),
  actions: {
    addArea (areaName: string) {
      const newArea = {
        name: areaName,
        id: getRandomInt(10001, 99999),
        categories: [],
        budgeted: 0,
        balance: 0,
        spent: 0,
        overview_expanded: false,
        progress: 0,
        hidden: false,
      }

      this.areas.push(newArea)
      const area: Area|undefined = this.getAreaById(newArea.id)

      if(area) {
        postArea(this.budget_id, newArea).then((response) => {
          if(response.id) {
            area.id = response.id
            this.layout_key++
          } else {
            // delete local area
            this.areas.splice(this.areas.findIndex(area => area.id === newArea.id), 1)
            throw new Error('Failed to add area (server)')
          }
        })
      } else {
        throw new Error('Failed to add area (client)')
      }

    },
    editAreaSortIndex (oldIndex: number, newIndex: number) {
      const area: Ref<Area> = ref(this.areas[oldIndex])

      patchArea(this.budget_id, area.value.id, {sort_index: newIndex}).then((response: Area) => {
        if(response) {
          area.value = response
        } else {
          // revert area to old index
          this.areas.splice(oldIndex, 0, this.areas.splice(newIndex, 1)[0])
          throw new Error('Failed to edit area (server)')
        }
      })
    },
    editCategorySortIndex (areaId: number, oldIndex: number, newIndex: number) {
      const area = this.getAreaById(areaId)

      if(area) {
        const category: Ref<Category> = ref(area.categories[oldIndex])
        patchCategory(this.budget_id, area.id, category.value.id, {sort_index: newIndex}).then((response: Category) => {
          if(response) {
            category.value = response
          } else {
            // revert category to old index
            area.categories.splice(oldIndex, 0, area.categories.splice(newIndex, 1)[0])
            throw new Error('Failed to edit category (server)')
          }
        })
      }
    },
    editCategoryAreaFrom (oldAreaId: number, oldIndex: number) {
      const oldArea = this.getAreaById(oldAreaId)
      const category: Ref<Category | undefined> = ref(oldArea?.categories[oldIndex])

      if(oldArea) {
        categoryMoveOld.index = oldIndex
        categoryMoveOld.areaId = oldAreaId
        categoryMoveOld.id = oldArea.categories[oldIndex].id
      } else {
        throw new Error('Old area not found')
      }
    },
    editCategoryAreaTo (newAreaId: number, newIndex: number, category: Category) {
      const oldArea = this.getAreaById(categoryMoveOld.areaId)
      const newArea = this.getAreaById(newAreaId)

      if(oldArea && newArea) {
        if(category) {
          patchCategory(this.budget_id, oldArea.id, categoryMoveOld.id, {area_id: newAreaId, sort_index: newIndex}).then((response: Category) => {
            if(response) {
              category = response
            } else {
              throw new Error('Failed to edit category (server)')
            }
          })
        } else {
            throw new Error('Category not found')
        }
      }
    },
    moveBalance (fromCategoryId: number, toCategoryId: number, amount: number) {
      const categoryTo = this.getCategoryById(toCategoryId)
      const categoryFrom = this.getCategoryById(fromCategoryId)

      amount = transformAmount(amount)

      if(categoryTo && categoryFrom) {
        categoryTo.budgeted += amount
        categoryTo.balance += amount
        categoryTo.goal.overall_progress += amount
        categoryFrom.budgeted -= amount
        categoryFrom.balance -= amount
        categoryFrom.goal.overall_progress -= amount

        const body = {
          amount: amount,
          move_to_category: toCategoryId,
        }

        reassignCategory(this.budget_id, categoryTo.area_id, fromCategoryId, body).then((response: any) => {
            if(!response) {
                categoryTo.budgeted -= amount
                categoryTo.balance -= amount
                categoryTo.goal.overall_progress -= amount
                categoryFrom.budgeted += amount
                categoryFrom.balance += amount
                categoryFrom.goal.overall_progress += amount
                throw new Error('Failed to move balance (server)')
            }
        })
      } else {
          throw new Error('Category not found')
      }
    },
    coverCategorySpending (categoryId: number, amount: number) {

    },
    editArea(updatedArea: Area) {
      const index = findIndexById(this.areas, updatedArea.id)
      if (index !== -1) {
        const areaOld = this.areas[index]
        this.areas[index] = updatedArea

        putArea(this.budget_id, updatedArea.id, updatedArea).then((response: Area) => {
          if(response) {
            this.areas[index] = response
          } else {
            this.areas[index] = areaOld
            throw new Error('Failed to edit area (server)')
          }
        })
      } else {
        throw new Error('Area not found')
      }
    },
    editAreaName (areaId: number, newName: string, callback?: Function) {
      const area = this.getAreaById(areaId)

      if(area && area.name === newName) {
        return
      }

      if(area && newName) {
        const areaOldName = area.name
        area.name = newName

        patchArea(this.budget_id, area.id, {name: newName}).then((response: Area) => {
          if(response) {
            area.name = response.name
          } else {
            area.name = areaOldName
            throw new Error('Failed to edit area (server)')
          }
        })
      } else {
        throw new Error('Area not found')
      }

      if(callback) {
          callback()
      }
    },
    fundArea (areaId: number, type: 'underfunded'|'balance'|'spending'|'reset' = 'underfunded') {
      let assignableAmount = 0
      let area = this.getAreaById(areaId)
      const apiBody = {
        categories: [] as Array<{ id: number; amount: number; }>
      }

      if(area) {
        const oldArea = area

        area.categories.forEach((category) => {
          if(type === 'underfunded') {
            const { $budgetStore } = useNuxtApp()
            let underfunded = 0
            if (category.goal.type === 'nt') {
              underfunded = -category.balance
            } else {
              underfunded = category.underfunded
            }
            assignableAmount = Math.max(Math.min(underfunded, $budgetStore.budget.balance), 0)
          } else if(type === 'balance') {
            assignableAmount = -category.balance
          } else if(type === 'spending') {
            assignableAmount = category.spent - category.budgeted
          } else if(type === 'reset') {
            assignableAmount = -category.budgeted
          }

          apiBody.categories.push({
            id: category.id,
            amount: assignableAmount
          })

          handleAssign(category, {type: 'add', value: assignableAmount, difference: assignableAmount}, false)
        })

        assignArea(this.budget_id, areaId, apiBody).then((response: Area) => {
          if(!response) {
            area = oldArea
            throw new Error('Failed to fund area (server)')
          }
        })
      } else {
        throw new Error('Area not found')
      }
    },
    editCategoryBudgeted (categoryId: number, newBudgeted: number, oldBudgeted: number, syncApi: boolean = true) {
        const category = this.getCategoryById(categoryId)
        const area = this.getAreaByCategoryId(categoryId)

        if(category && oldBudgeted === newBudgeted) {
            return
        }

        if(category && area) {
          category.budgeted = newBudgeted

          if(syncApi) {
            patchCategory(this.budget_id, area.id, categoryId, {budgeted: newBudgeted}).then((response: Category) => {
              if(response) {
                category.budgeted = response.budgeted
              } else {
                category.budgeted = oldBudgeted
                throw new Error('Failed to edit category (server)')
              }
            })
          }
        } else {
            throw new Error('Area not found')
        }
    },
    editCategory (updatedCategory: Category) {
      const areaIndex = findIndexById(this.areas, updatedCategory.area_id)
      if (areaIndex !== -1) {
        const categoryIndex = findIndexById(this.areas[areaIndex].categories, updatedCategory.id)
        if (categoryIndex !== -1) {
          this.areas[areaIndex].categories[categoryIndex] = updatedCategory
        } else {
          throw new Error('Category not found')
        }
      }
    },
    editCategoryName (categoryId: number, newName: string, callback?: Function) {
      const category = this.getCategoryById(categoryId)
      const area = this.getAreaByCategoryId(categoryId)

      if(category && category.name === newName) {
        return
      }

      if(category && area && newName) {
        const categoryOldName = category.name
        category.name = newName

        patchCategory(this.budget_id, area.id, categoryId, {name: newName}).then((response: Category) => {
          if(response) {
            category.name = response.name
          } else {
            category.name = categoryOldName
            throw new Error('Failed to edit category (server)')
          }
        })
      } else {
        throw new Error('Area not found')
      }

      if(callback) {
        callback()
      }
    },
    editCategoryGoal (categoryId: number, newGoal: Goal, callback?: Function) {
      const category = this.getCategoryById(categoryId)
      const area = this.getAreaByCategoryId(categoryId)

      if(category && category.goal === newGoal) {
        return
      }

      if(category && area && newGoal) {
        const categoryOldGoal = category.goal
        category.goal = newGoal

        const newGoalBody= {
            goal_type: newGoal.type,
            goal_target: newGoal.target,
            goal_frequency: newGoal.frequency,
            goal_due_by: newGoal.due_by,
            goal_repeat_every: newGoal.repeat_every,
            goal_repeat_anchor: newGoal.repeat_anchor,
        }

        patchCategory(this.budget_id, area.id, categoryId, newGoalBody).then((response: Category) => {
          if(response) {
            category.name = response.name
          } else {
            category.goal = categoryOldGoal
            throw new Error('Failed to edit category (server)')
          }
        })
      } else {
        throw new Error('Area not found')
      }

      if(callback) {
        callback()
      }
    },
    deleteArea (deleteAreaId: number, destinationCategoryId: number) {
      //TODO: This function currently just transfers values, eventually it will have to transfer transactions as well

      // find area, move all categories balance, budgeted and spent to destination category. Delete area
        const deleteAreaIndex = this.areas.findIndex(area => area.id === deleteAreaId)
        if (deleteAreaIndex !== -1) {
          const deleteArea = this.areas[deleteAreaIndex]
          const destinationArea = this.getAreaByCategoryId(destinationCategoryId)

          if(!destinationArea) {
            throw new Error('Destination area not found')
          }

          const destinationCategoryIndex = destinationArea.categories.findIndex(category => category.id === destinationCategoryId)

          if (destinationCategoryIndex !== -1) {
            destinationArea.categories[destinationCategoryIndex].balance += deleteArea.balance
            destinationArea.categories[destinationCategoryIndex].budgeted += deleteArea.budgeted
            destinationArea.categories[destinationCategoryIndex].spent += deleteArea.spent

            //delete area
            this.areas.splice(deleteAreaIndex, 1)

            // delete area on backend
            delArea(this.budget_id, deleteAreaId).then((response: Response) => {
                if(false) {
                    // revert delete area
                    this.areas.splice(deleteAreaIndex, 0, deleteArea)
                    throw new Error('Failed to delete area (server)')
                } else {
                  this.layout_key--
                }
            })
          } else {
            throw new Error('Destination category not found')
          }
        } else {
          throw new Error('Area not found')
        }
    },
    deleteCategory (deleteCategoryId: number, destinationCategoryId: number) {
        const deleteCategoryArea = this.getAreaByCategoryId(deleteCategoryId)
        const destinationCategoryArea = this.getAreaByCategoryId(destinationCategoryId)
        const deleteCategory = this.getCategoryById(deleteCategoryId)

        if(!deleteCategoryArea) {
          throw new Error('Delete category area not found')
        }
        if(!destinationCategoryArea) {
          throw new Error('Destination category area not found')
        }

        // find delete category, move all balance, budgeted and spent to destination category. Delete category
      const destinationCategoryIndex = destinationCategoryArea.categories.findIndex(category => category.id === destinationCategoryId)
      const deleteCategoryIndex = deleteCategoryArea.categories.findIndex(category => category.id === deleteCategoryId)

      if (destinationCategoryIndex !== -1 && deleteCategoryIndex !== -1 && deleteCategory) {
        destinationCategoryArea.categories[destinationCategoryIndex].balance += deleteCategoryArea.categories[deleteCategoryIndex].balance
        destinationCategoryArea.categories[destinationCategoryIndex].budgeted += deleteCategoryArea.categories[deleteCategoryIndex].budgeted
        destinationCategoryArea.categories[destinationCategoryIndex].spent += deleteCategoryArea.categories[deleteCategoryIndex].spent

        //delete category
        deleteCategoryArea.categories.splice(deleteCategoryIndex, 1)

        // delete category on backend
        delCategory(this.budget_id, deleteCategoryArea.id, deleteCategoryId).then((response: Response) => {
          if(false) {
            // revert delete area
            deleteCategoryArea.categories.splice(deleteCategoryIndex, 0, deleteCategory)
            throw new Error('Failed to delete category (server)')
          }
        })
      } else {
        throw new Error('Category not found')
      }
    },
    addCategory (newCategory: Category, areaId: number) {
        const areaIndex = findIndexById(this.areas, areaId)
        if (areaIndex !== -1) {
            newCategory.id = getRandomInt(10001, 99999)
            this.areas[areaIndex].categories.push(newCategory)

            const category: Category|undefined = this.getCategoryById(newCategory.id)

            if(category) {
              postCategory(this.budget_id, areaId, newCategory).then((response) => {
                if(response.id) {
                  category.id = response.id
                } else {
                  throw new Error('Failed to add category (server)')
                }
              })
            } else {
              throw new Error('Failed to add category (client)')
            }
        } else {
            throw new Error('Area not found')
        }
    },
  },
  getters: {
    getProgressComplete: (state) => {
      return (progress: number) => !(progress * 100 < 100)
    },
    getCategoryStats: (state) => {
      return (category: Category) => {
        return [
          { name: 'Spent', value: transformAmount(category.spent) },
          { name: 'Balance', value: transformAmount(category.balance) },
        ]
      }
    },
    getAreas: (state) => {
      return async (budgetId: number = state.budget_id): Promise<Area[] | undefined> => {
        const cacheExpiryMinutes = useRuntimeConfig().public.cacheExpiryMinutes as unknown as number * 60 * 1000
        const now = Date.now();

        if (now - state.last_fetch > cacheExpiryMinutes) {
          state.areas = await fetchAreas(budgetId);
          state.areas.forEach(area => {
            updateAreaProps(area);
          });

          state.layout_key = state.areas.length;
          state.last_fetch = now;
        }

        useBudgetStore().area_skeletons = state.areas.map(area => {
            return {
                categories: area.categories.length
            }
        })

        return state.areas;
      }
    },
    getCategoryById: (state) => {
        return (categoryId: number): Category | undefined => {
            return state.areas.reduce((acc, area) => {
            return acc.concat(area.categories)
            }, [] as Category[]).find(category => category.id === categoryId)
        }
    },
    getAreaById: (state) => {
        return (areaId: number): Area | undefined => {
            return state.areas.find(area => area.id === areaId)
        }
    },
    getAreaByCategoryId: (state) => {
      return (categoryId: number): Area | undefined => {
        return state.areas.find(area => area.categories.find(category => category.id === categoryId))
      }
    },
    getCategories: (state) => {
      return (areaId?: number): Category[] | undefined => {
        let categories: Category[] = []
        if (areaId) {
          const areaIndex = findIndexById(state.areas, areaId)
          if (areaIndex !== -1) {
            categories = state.areas[areaIndex].categories
          }
        } else {
          state.areas.forEach(area => {
            categories = categories.concat(area.categories)
          })
        }

        return categories
      }
    },
  }
})


function findIndexById(items: any[], id: number): number {
  return items.findIndex(item => item.id === id)
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}