import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    isSidebarLarge: true,
    isBottomBarHidden: false,
    isBalanceExpanded: true,
    isAssignExpanded: false
  }),
  actions: {
    toggleSidebarSize () {
      this.isSidebarLarge = !this.isSidebarLarge
    },
    toggleBottomBar () {
      this.isBottomBarHidden = !this.isBottomBarHidden
    },
    toggleBalanceExpanded (expand: boolean) {
      this.isBalanceExpanded = expand
    },
    toggleAssignExpanded (expand: boolean) {
      this.isAssignExpanded = expand
    }
  },
  persist: {
    storage: persistedState.localStorage,
  }
})
