import { useUserStore } from '~/stores/user'
import { useAppStore } from '~/stores/app'
import { useAreaStore } from '~/stores/areas'
import { useBudgetStore } from '~/stores/budget'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      userStore: useUserStore(),
      appStore: useAppStore(),
      budgetStore: useBudgetStore(),
      areaStore: useAreaStore()
    }
  }
})
