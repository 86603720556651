import { $fetch, FetchError } from "ofetch";
import { parseCookies } from "h3";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";

export const $efetch = $fetch.create({
    credentials: "include",
    async onRequest({ request, options }) {
        const { apiUrl, appUrl } = useRuntimeConfig().public;
        const event = process.nitro ? useEvent() : null;
        let token = event
            ? parseCookies(event)[CSRF_COOKIE]
            : useCookie(CSRF_COOKIE, {  domain: !process.dev ? '.envolop.com' : 'localhost' }).value;

        // on client initiate a csrf request and get it from the cookie set by laravel
        if (
            process.client &&
            ["post", "delete", "put", "patch"].includes(
                options?.method?.toLowerCase() ?? ""
            )
        ) {
            token = await initCsrf();
        }

        let headers: any = {
            accept: "application/json",
            ...options?.headers,
            ...(token && { [CSRF_HEADER]: token }),
        };

        if (process.server) {
            const cookieString = event
                ? event.headers.get("cookie")
                : useRequestHeaders(["cookie"]).cookie;

            headers = {
                ...headers,
                ...(cookieString && { cookie: cookieString }),
                referer: appUrl,
            };
        }

        options.headers = headers;
        options.baseURL = apiUrl;
    },
    async onResponseError({ response }) {
        const status = response.status;
        if ([500].includes(status)) {
            console.error("[Server Error]", response.statusText, response._data);
        }

        if ([419].includes(status)) {
            navigateTo("/login");
        }

        if ([401].includes(status)) {
            const { $userStore } = useNuxtApp();
            $userStore.resetUser();
            navigateTo("/login");
        }

        if ([409].includes(status)) {
            navigateTo("/verify-email");
        }
    },
});

async function initCsrf() {
    const { apiUrl } = useRuntimeConfig().public;
    const existingToken = useCookie(CSRF_COOKIE, { domain: !process.dev ? '.envolop.com' : 'localhost' }).value;

    if (existingToken) return existingToken;

    await $fetch("/sanctum/csrf-cookie", {
        baseURL: apiUrl,
        credentials: "include",
    });

    return useCookie(CSRF_COOKIE).value;
}