export const transformAmount = (value: number | string): number => {
  if (typeof value === 'string') {
    value = parseFloat(value.replace(',', '.'))
  }

  // if value is not a number (NAN) after parseFloat
  if (isNaN(value)) {
    return 0 // hands off the NANs, return 0 instead
  }

  return Math.round(value * 100) / 100
}

export const formatAmountWithCurrency = (value: number | string, currency?: Currency): string => {
  if(value === undefined || value === null) {
    return ''
  }

  if(!currency) {
    const { $budgetStore } = useNuxtApp()
    currency = $budgetStore.budget.currency
  }

  if (typeof value === 'string') {
    value = parseFloat(value.replace(',', '.'))
  }

  value = value.toFixed(currency.decimal_places) // round value to decimal places first
  const parts = value.split('.')

  // groups of three
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, currency.group_separator)
  let result = parts.join(currency.decimal_separator)

  if (currency.display_symbol) {
    if (currency.symbol_placement === 'before') {
      result = `${currency.symbol} ${result}`
    } else {
      result = `${result} ${currency.symbol}`
    }
  }

  return result
}
