export default defineAppConfig({
  ui: {
    primary: 'green',
    gray: 'zinc',
    button: {
      base: 'transition duration-150 ease-in-out disabled:opacity-60',
      color: {
        white: {
          solid: 'shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700/70 text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white dark:bg-black dark:hover:bg-gray-900 dark:disabled:bg-gray-900 focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          outline: 'shadow-sm ring-1 ring-inset ring-gray-300/70 dark:ring-gray-700/70 text-gray-900 dark:text-white bg-transparent hover:bg-gray-200/60 disabled:bg-white dark:bg-transparent dark:hover:bg-gray-800/70 dark:disabled:bg-gray-900 focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          ghost: 'text-gray-900 dark:text-white hover:bg-gray-200/60 dark:hover:bg-gray-800/70 focus-visible:ring-inset disabled:bg-transparent dark:disabled:bg-transparent focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          soft: 'text-gray-900 dark:text-white bg-gray-500/10 hover:bg-gray-300/80 dark:bg-gray-800/75 dark:hover:bg-gray-700/75 focus-visible:ring-inset focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400'
        },
        primary: {
          soft: 'text-green-600 dark:text-green-400 bg-green-100 hover:bg-green-200 disabled:bg-green-50 dark:bg-green-950 dark:hover:bg-green-900 dark:disabled:bg-green-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-green-500 dark:focus-visible:ring-green-400',
        },
        green: {
          soft: 'text-green-700 dark:text-green-400 bg-green-200 hover:bg-green-300 disabled:bg-green-50 dark:bg-green-950 dark:hover:bg-green-900 dark:disabled:bg-green-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-green-500 dark:focus-visible:ring-green-400',
        },
        yellow: {
          soft: 'text-yellow-700 dark:text-yellow-400 bg-yellow-200 hover:bg-yellow-300 disabled:bg-yellow-50 dark:bg-yellow-950 dark:hover:bg-yellow-900 dark:disabled:bg-yellow-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400',
        },
        danger: {
          solid: "shadow-sm text-white dark:text-red-100 bg-red-500 hover:bg-red-600 disabled:bg-red-500 dark:bg-red-600 dark:hover:bg-red-600 dark:disabled:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 dark:focus-visible:outline-red-400",
          soft: 'text-red-600 dark:text-red-400 bg-red-100 hover:bg-red-200 disabled:bg-red-50 dark:bg-red-950 dark:hover:bg-red-900 dark:disabled:bg-red-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-red-500 dark:focus-visible:ring-red-400',
        },
        red: {
          soft: 'text-red-600 dark:text-red-400 bg-red-100 hover:bg-red-200 disabled:bg-red-50 dark:bg-red-950 dark:hover:bg-red-900 dark:disabled:bg-red-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-red-500 dark:focus-visible:ring-red-400',
        },
      },
      variant: {
        solid: 'shadow-sm text-white dark:text-gray-900 bg-{color}-500 hover:bg-{color}-600 disabled:bg-{color}-500 dark:bg-{color}-400 dark:hover:bg-{color}-500 dark:disabled:bg-{color}-400 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-{color}-500 dark:focus-visible:outline-{color}-400',
        outline: 'text-white dark:text-gray-900 bg-transparent hover:bg-{color}-600 disabled:bg-{color}-500 dark:bg-transparent dark:hover:bg-{color}-500 dark:disabled:bg-{color}-400 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-{color}-500 dark:focus-visible:outline-{color}-400',
        soft: 'text-{color}-600 dark:text-{color}-400 bg-{color}-100 hover:bg-{color}-200 disabled:bg-{color}-50 dark:bg-{color}-950 dark:hover:bg-{color}-900 dark:disabled:bg-{color}-950 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
        ghost: 'text-{color}-500 dark:text-{color}-400 hover:bg-{color}-50 disabled:bg-transparent dark:hover:bg-{color}-950 dark:disabled:bg-transparent focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
        link: 'text-{color}-500 hover:text-{color}-600 disabled:text-{color}-500 dark:text-{color}-400 dark:hover:text-{color}-500 dark:disabled:text-{color}-400 underline-offset-4 hover:underline focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400'
      },
      default: {
        size: 'md',
        variant: 'solid',
        loadingIcon: 'i-solar-refresh-outline'
      }
    },
    select: {
      color: {
        white: {
          outline: 'shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700/70 text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white dark:bg-black dark:hover:bg-gray-900 dark:disabled:bg-gray-900 focus:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400'
        }
      },
      variant: {
        outline: 'shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 focus:ring-2 focus:ring-{color}-500 dark:focus:ring-{color}-400'
      },
      default: {
        size: 'lg'
      }
    },
    input: {
      base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0',
      color: {
        white: {
          outline: 'shadow-sm bg-white dark:bg-black text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-800 focus:ring-1 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400 hover:ring-gray-400 dark:hover:ring-gray-700',
          title: 'bg-transparent p-1 duration-0 py-0 text-2xl rounded-none text-gray-900 dark:text-white border-dashed border-transparent border-b outline-none !ring-0 focus:ring-o focus:border-gray-500 dark:focus:border-gray-500 hover:border-gray-400 dark:hover:border-gray-700',
          titlePronounced: 'bg-transparent p-1 duration-0 text-2xl rounded-none text-gray-900 dark:text-white border-dashed border-b outline-none !ring-0 focus:ring-o focus:border-gray-500 dark:focus:border-gray-500 border-gray-400 dark:border-gray-700',
          titleAmount: 'bg-transparent p-1 duration-0 text-4xl rounded-none text-gray-900 dark:text-white border-dashed border-b outline-none !ring-0 focus:ring-o focus:border-gray-500 dark:focus:border-gray-500 border-gray-400 dark:border-gray-700'
        },
        gray: {
          outline: 'shadow-sm bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-800 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400'
        }
      },
      variant: {
        outline: 'shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset focus:ring-inset ring-{color}-500 dark:ring-{color}-500 focus:ring-1 focus:ring-{color}-500 dark:focus:ring-{color}-400',
        none: 'bg-transparent focus:ring-0 focus:shadow-none'
      },
      default: {
        size: 'lg',
        color: 'white',
        variant: 'outline'
      }
    },
    card: {
      background: 'bg-white dark:bg-black',
      backgroundPronounced: 'bg-gray-100 dark:bg-gray-800/30 dark:backdrop-blur-lg',
      rounded: 'rounded-lg',
      shadow: 'shadow-[rgba(17,_17,_26,_0.04)_0px_4px_12px]',
      body: {
        padding: 'px-6 py-7 sm:p-8'
      },
      ring: 'ring-1 ring-gray-200 dark:ring-gray-800',
      ringSoft: 'ring-1 ring-gray-200/80 dark:ring-gray-800/80'
    },
    checkbox: {
      background: 'bg-white dark:bg-black',
      color: 'text-{color}-500 dark:text-gray-500',
      base: 'h-5 w-5 duration-150 ease-in-out dark:checked:bg-transparent dark:checked:border-gray-700 dark:indeterminate:bg-transparent dark:indeterminate:border-gray-700 disabled:opacity-50 disabled:cursor-not-allowed focus:ring-0 focus:ring-transparent focus:ring-offset-transparent',
      ring: 'focus-visible:ring-1 focus-visible:ring-{color}-500 dark:focus-visible:ring-gray-500 focus-visible:ring-offset-1 focus-visible:ring-offset-white dark:focus-visible:ring-offset-gray-950'
    },
    formGroup: {
      help: 'mt-1 text-gray-500 dark:text-gray-400',
      error: 'mt-1 text-red-500 dark:text-red-500',
      label: {
        wrapper: 'flex content-center items-center justify-between',
        base: 'block font-medium text-gray-700 dark:text-gray-200',
        required: "after:content-['*'] after:ms-0.5 after:text-red-500 dark:after:text-red-400"
      }
    },
    commandPalette: {
      wrapper: 'flex flex-col rounded-xl flex-1 min-h-0 max-h-[75vh] divide-y divide-gray-200 dark:divide-gray-100/10 bg-transparent dark:bg-transparent',
      container: 'relative flex-1 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-100/10 scroll-py-2',
      input: {
        base: 'w-full h-14 px-4 placeholder-gray-900/40 dark:placeholder-gray-100/40 bg-transparent border-0 text-gray-900 dark:text-white focus:ring-0 focus:outline-none',
        icon: {
          base: 'pointer-events-none absolute start-4 text-gray-900/40 dark:text-gray-100/40',
          size: 'h-4 w-4',
          padding: 'ps-10'
        }
      },
      group: {
        wrapper: 'p-1',
        label: 'px-2 my-2 text-xs font-semibold text-gray-900/30 dark:text-gray-100/30',
        active: 'flex-shrink-0 text-gray-800/50 dark:text-gray-200/40',
        inactive: 'flex-shrink-0 text-gray-800/50 dark:text-gray-200/40',
        command: {
          base: 'flex justify-between select-none !cursor-default items-center rounded-md px-2 py-2.5 gap-2 relative',
          active: 'bg-gray-900/[5%] dark:bg-gray-100/[7.5%] text-gray-900 dark:text-white',
          prefix: 'text-gray-800/50 dark:text-gray-200/40',
          suffix: 'text-gray-800/50 dark:text-gray-200/40',
          container: 'flex items-center gap-3 min-w-0',
          icon: {
            base: 'flex-shrink-0 w-5 h-5',
            active: 'text-gray-900 dark:text-white/90',
            inactive: 'text-gray-800/50 dark:text-gray-200/40'
          },
          arrow: {
            active: 'text-gray-900 dark:text-white/90',
            inactive: 'text-gray-800/50 dark:text-gray-200/40'
          },
          avatar: {
            size: '2xs'
          }
        }
      }
    },
    modal: {
      background: "bg-white/75 dark:bg-black/75 backdrop-blur-lg",
      ring: "ring-1 ring-gray-300/75 dark:ring-gray-800",
      container: "items-start",
      rounded: "rounded-lg",
      shadow: '!shadow-[rgba(17,_17,_26,_0.06)_0px_4px_12px]',
      overlay: {
        base: "fixed inset-0 transition-opacity",
        background: "bg-gray-200/75 dark:bg-black/60",
        transition: {
          enter: "ease-out duration-150",
          enterFrom: "opacity-0",
          enterTo: "opacity-100",
          leave: "ease-in duration-150",
          leaveFrom: "opacity-100",
          leaveTo: "opacity-0"
        }
      },
      transition: {
        enter: 'ease-out duration-150',
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100',
        leave: 'ease-in duration-150',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
      }
    },
    dropdown: {
      wrapper: 'relative inline-flex text-left rtl:text-right',
      container: 'z-40',
      width: 'w-48',
      height: '',
      background: 'bg-white dark:bg-black/90 dark:backdrop-blur-lg',
      backgroundDark: 'bg-white dark:bg-black/80 dark:backdrop-blur-lg',
      shadow: '!shadow-[rgba(17,_17,_26,_0.06)_0px_4px_12px]',
      rounded: 'rounded-lg',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-800',
      base: 'relative focus:outline-none overflow-y-auto scroll-py-1',
      divide: 'divide-y divide-gray-900/10 dark:divide-gray-200/10',
      padding: 'p-1',
      item: {
        base: 'group/item flex items-center gap-2 w-full cursor-default focus-visible:outline-0',
        rounded: 'rounded-md',
        padding: 'px-2 py-2',
        size: 'text-sm',
        active: 'bg-gray-200/60 dark:bg-gray-800/70 text-gray-900 dark:text-white',
        inactive: 'text-gray-700 dark:text-gray-200',
        disabled: 'cursor-not-allowed opacity-50',
        icon: {
          base: 'flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 group-hover/item:text-gray-500 group-hover/item:dark:text-gray-400',
          active: 'text-gray-500 dark:text-gray-400',
          inactive: 'text-gray-400 dark:text-gray-500'
        },
        avatar: {
          base: 'flex-shrink-0',
          size: '3xs'
        },
        shortcuts: 'hidden md:inline-flex flex-shrink-0 gap-0.5 ms-auto'
      }
    },
    selectMenu: {
      container: 'z-20',
      width: 'w-full',
      height: 'max-h-80',
      base: 'relative focus:outline-none overflow-y-auto scroll-py-1',
      background: 'bg-white dark:bg-black',
      shadow: 'shadow-none !shadow-[rgba(17,_17,_26,_0.06)_0px_4px_12px]',
      rounded: 'rounded-lg',
      padding: 'p-1',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-800',
      input: "block w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-2.5 text-gray-700 dark:text-gray-200 bg-white dark:bg-black border-0 border-b border-gray-200 dark:border-gray-800 focus:border-inherit sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-600 focus:outline-none",
      option: {
        base: 'group/item flex items-center gap-2 w-full cursor-default focus-visible:outline-0',
        rounded: 'rounded-md',
        padding: 'px-2 py-2',
        size: 'text-sm',
        active: 'bg-gray-200/60 dark:bg-gray-800/70 text-gray-900 dark:text-white',
        inactive: 'text-gray-700 dark:text-gray-200',
        disabled: 'cursor-not-allowed opacity-50',
        color: 'text-gray-900 dark:text-white',
        container: 'flex items-center gap-2 min-w-0',
        selected: 'pe-9',
        empty: 'text-sm text-gray-400 dark:text-gray-500 px-2 py-1.5',
        icon: {
          base: 'flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 group-hover/item:text-gray-500 group-hover/item:dark:text-gray-400',
          active: 'text-gray-500 dark:text-gray-400',
          inactive: 'text-gray-400 dark:text-gray-500'
        },
        selectedIcon: {
          wrapper: 'absolute inset-y-0 end-0 flex items-center',
          padding: 'pe-4',
          base: 'h-4 w-4 text-gray-900 dark:text-white flex-shrink-0'
        },
        avatar: {
          base: 'flex-shrink-0',
          size: '3xs'
        },
        chip: {
          base: 'flex-shrink-0 w-2 h-2 mx-1 rounded-full'
        }
      }
    },
    popover: {
      background: 'bg-white dark:bg-black/90 dark:backdrop-blur-lg',
      backgroundNoBlur: 'bg-white dark:bg-[#151517]',
      shadow: 'shadow-none !shadow-[rgba(17,_17,_26,_0.06)_0px_4px_12px]',
      rounded: 'rounded-lg',
      container: 'z-40',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-800'
    },
    badge: {
      color: {
        white: {
          solid: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-800 text-gray-900 dark:text-white bg-white dark:bg-gray-900'
        },
        gray: {
          solid: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-800'
        },
        black: {
          solid: 'text-white dark:text-gray-900 bg-gray-900 dark:bg-white'
        }
      },
      variant: {
        soft: 'bg-{color}-500 bg-opacity-10 dark:bg-opacity-20 text-{color}-900 dark:text-{color}-100  text-opacity-50 dark:text-opacity-50'
      }
    },
    tooltip: {
      container: 'z-50'
    },
    slideover: {
      wrapper: 'fixed inset-0 flex z-50',
      overlay: {
        base: 'fixed inset-0 transition-opacity',
        background: 'bg-gray-200/75 dark:bg-black/60',
        transition: {
          enter: 'ease-in-out duration-500',
          enterFrom: 'opacity-0',
          enterTo: 'opacity-100',
          leave: 'ease-in-out duration-500',
          leaveFrom: 'opacity-100',
          leaveTo: 'opacity-0'
        }
      },
      base: 'relative flex-1 flex flex-col w-full focus:outline-none',
      background: 'bg-gray-50 dark:bg-black',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-50/10',
      rounded: '',
      padding: '',
      shadow: 'shadow-xl',
      width: 'w-screen max-w-md',
      transition: {
        enter: 'transform transition ease-in-out duration-300',
        leave: 'transform transition ease-in-out duration-300'
      }
    },
    tabs: {
      wrapper: 'relative space-y-2',
      container: 'relative w-full',
      base: 'focus:outline-none',
      list: {
        base: 'relative',
        background: 'bg-white dark:bg-black',
        rounded: 'rounded-lg',
        shadow: '',
        padding: 'p-1',
        height: 'h-10',
        width: 'w-full',
        marker: {
          wrapper: 'absolute top-[4px] left-[4px] duration-200 ease-out focus:outline-none',
          base: 'w-full h-full',
          background: 'bg-gray-200/75 dark:bg-gray-800',
          rounded: 'rounded-md',
          shadow: ''
        },
        tab: {
          base: 'relative inline-flex items-center justify-center flex-shrink-0 w-full whitespace-nowrap ui-focus-visible:outline-0 ui-focus-visible:ring-1 ui-focus-visible:ring-primary-500 dark:ui-focus-visible:ring-primary-400 ui-not-focus-visible:outline-none focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 transition-colors duration-200 ease-out',
          background: '',
          active: 'text-gray-900 dark:text-white',
          inactive: 'text-gray-500 dark:text-gray-400',
          height: 'h-8',
          padding: 'px-3',
          size: 'text-sm',
          font: 'font-medium',
          rounded: 'rounded-md',
          shadow: ''
        }
      }
    },
    alert: {
      variant: {
        solid: "bg-{color}-500 dark:bg-{color}-400 text-white dark:text-gray-900",
        outline: "text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400",
        soft: "bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400",
        subtle: "bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 ring-opacity-25 dark:ring-opacity-25"
      },
    },
  }
})
