import { default as google9ECHrpOmgmMeta } from "/vercel/path0/pages/auth/callback/google.vue?macro=true";
import { default as budget6q9feBvlxrMeta } from "/vercel/path0/pages/budget.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
export default [
  {
    name: google9ECHrpOmgmMeta?.name ?? "auth-callback-google",
    path: google9ECHrpOmgmMeta?.path ?? "/auth/callback/google",
    meta: google9ECHrpOmgmMeta || {},
    alias: google9ECHrpOmgmMeta?.alias || [],
    redirect: google9ECHrpOmgmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/callback/google.vue").then(m => m.default || m)
  },
  {
    name: budget6q9feBvlxrMeta?.name ?? "budget",
    path: budget6q9feBvlxrMeta?.path ?? "/budget",
    meta: budget6q9feBvlxrMeta || {},
    alias: budget6q9feBvlxrMeta?.alias || [],
    redirect: budget6q9feBvlxrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/budget.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  }
]