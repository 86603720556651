import { defineStore } from 'pinia'
import { useRuntimeConfig } from '#app'

export type LoginCredentials = {
  email: string;
  password: string;
  remember: boolean;
};

export type RegisterCredentials = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export type ResetPasswordCredentials = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

export const useUserStore = defineStore('user', {
  state: (): User => ({
    id: undefined,
    email: undefined,
    name: undefined
  }),
  actions: {
    async refresh() {
      try {
        this.$state = await this.fetchCurrentUser()
      } catch {
        this.resetUser()
      }
    },

    async fetchCurrentUser() {
      try {
        return await $efetch("/api/me")
      } catch (error: any) {
        if ([401, 419].includes(error?.response?.status)) return null
        throw error
      }
    },

    async login (credentials: LoginCredentials) {
      if (this.$state.id !== undefined) return

      await $efetch("/login", { method: "post", body: credentials })
      await this.refresh()
    },

    loginWithProvider (provider = 'google', remember = false) {
      if (provider === 'google') {
        navigateTo(useRuntimeConfig().public.apiUrl + '/auth/google?remember=' + (remember ? 1 : 0), { external: true })
      }
    },

    async providerCallback (provider: string, code: string) {
      await $efetch('/auth/' + provider + '/callback', { method: "post", body: { code } })
      await this.refresh()
    },

    async logout() {
      if (this.$state.id === undefined) return;

      await $efetch("/logout", { method: "post" });
      this.resetUser()

      await navigateTo("/login");
    },

    async resetPassword (credentials: ResetPasswordCredentials) {
      return await $efetch<{ status: string }>("/reset-password", {
        method: "post",
        body: credentials,
      });
    },

    async forgotPassword (email: string) {
      return await $efetch<{ status: string }>("/forgot-password", {
        method: "post",
        body: { email },
      });
    },

    async register (credentials: RegisterCredentials) {
      await $efetch("/register", { method: "post", body: credentials })
      await this.refresh()
    },

    resetUser () {
      this.$state = {
        id: undefined,
        email: undefined,
        name: undefined
      }
    }

  },
  persist: true
})
